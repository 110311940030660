<template>
    <div class="bg">
        <img src="../../../../assets/mobile/common/pic/logoWW.png" alt="" class="logo" @click="backToHome">
        <div class="loginWindow">
            <div class="title">宁讯科技客户登录页面</div>
            <div class="acc">
                <span class="text">账号</span>
                <input type="text">
            </div>
            <div class="psw">
                <span class="text">密码</span>
                <input type="password">
            </div>
            <div class="loginBtn">登&nbsp;&nbsp;录</div>
            <div class="action">
                更多技术支持请联系地区合作SI商或邮件：support@linkom.com.cn<br>
                页面内所有说明资料及图片仅供参考，最终解释权归杭州宁讯信息科技有限公司所有
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        // 返回首页
        backToHome() {
            this.$router.replace('/mHome')
        },
    }
}
</script>

<style scoped>
.bg {
    position: relative;
    padding-top: 1000px;
    width: 100%;
    height: 100vh;
    background: url(../../../../assets/mobile/img/support/bg.png) center no-repeat;
    background-size: 100%;
}

.bg .logo {
    position: absolute;
    top: 100px;
    left: 100px;
    height: 180px;
}

.bg .loginWindow {
    position: relative;
    margin: 0 auto;
    padding-top: 300px;
    width: 100%;
    height: 100%;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    background-color: #fff;
    text-align: center;
}

.bg .loginWindow .title {
    margin-bottom: 200px;
    font-size: 120px;
    font-weight: 700;
    color: #988634;
}

.bg .loginWindow .acc {
    font-size: 80px;
    font-weight: 700;
    color: #988634;
}

.bg .loginWindow .acc input {
    margin-left: 30px;
    margin-bottom: 50px;
}

.bg .loginWindow .psw {
    font-size: 80px;
    font-weight: 700;
    color: #988634;
}

.bg .loginWindow .psw input {
    margin-left: 30px;
    margin-bottom: 200px;
}

.bg .loginWindow .loginBtn {
    margin: 0 auto;
    width: 600px;
    height: 200px;
    line-height: 200px;
    color: #fff;
    border-radius: 25px;
    background-color: #988634;
}

.bg .loginWindow .action {
    position: absolute;
    left: 75px;
    bottom: 100px;
    text-align: center;
    font-size: 48px;
}
</style>